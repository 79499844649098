<script setup lang="ts">
import MeService from '@/core/shared/me/me.service';
import SubscriptionService from '@/core/shared/subscription/subscription.service';
import { DrawerService } from '@/core/shared/drawer/drawer.service';
import { computed } from 'vue';
import { useDisplay } from 'vuetify';
import UserInfo from '../UserInfo/UserInfo.vue';
import CompanyInfo from '../CompanyInfo/CompanyInfo.vue';
import GetHelpContent from '../GetHelp/GetHelpContent.vue';
import InviteTeammateContent from '../InviteTeammate/InviteTeammateContent.vue';
import CompaniesContent from '../CompanyInfo/CompaniesContent.vue';
import MobileDrawerItem from './MobileDrawerItem.vue';
import PlanUsage from '../PlanUsage/PlanUsage.vue';
import Divider from '../../Divider/Divider.vue';
import ChevronLeftSvg from '@/assets/svg/chevron-left.svg';
import { ref } from 'vue';
import { RouterLink, useRoute } from 'vue-router';
import AuthenticationService from '@/core/shared/authentication/authentication.service';

const route = useRoute();
const display = useDisplay();
const meService = new MeService();
const drawerService = new DrawerService();
const subscriptionService = new SubscriptionService();
const authenticationService = new AuthenticationService();

const isMobile = computed(() => display.smAndDown.value);
const detailView = ref<'get-help' | 'invite-teammate' | 'companies'>();

const hasPerformanceDashboard = computed(() => {
  return meService.isEmployerAdmin;
});

const conversationsTarget = computed(() => {
  if (drawerService.visitedConversationsTabRouteName) {
    return {
      name: drawerService.visitedConversationsTabRouteName,
      query: route.query,
      params: route.params,
    };
  }

  return { name: 'conversations', query: route.query, params: route.params };
});

function onUserClick() {
  const userProfileId = meService.userProfile?.id;
  meService.redirectToUI2(`/employer/users/${userProfileId}`);
}

function onSettingsClick() {
  meService.redirectToUI2('/employer/settings');
}

function onLogoutClick() {
  authenticationService.logout();
  meService.redirectToUI2('/employer/auth/logout');
}
</script>

<template>
  <v-navigation-drawer
    v-if="isMobile"
    :model-value="drawerService.isMobileDrawerOpen"
    temporary
    floating
    width="376"
    location="right"
    class="rounded-l-xl"
  >
    <!-- General View -->
    <div v-if="!detailView" class="flex h-full flex-col justify-between py-4">
      <div class="flex flex-col font-bold">
        <!-- Jobs -->
        <RouterLink :to="conversationsTarget" class="drawer-item" active-class="drawer-item-active">
          <MobileDrawerItem>Manage Jobs</MobileDrawerItem>
        </RouterLink>

        <Divider class="mx-3" />

        <!-- Candidates -->
        <RouterLink to="/candidates" class="drawer-item" active-class="drawer-item-active">
          <MobileDrawerItem>Candidate Archive</MobileDrawerItem>
        </RouterLink>

        <Divider class="mx-3" />

        <!-- Search -->
        <RouterLink to="/sourcing" class="drawer-item" active-class="drawer-item-active">
          <MobileDrawerItem>FactoryFix Talent Pool</MobileDrawerItem>
        </RouterLink>

        <!-- Performance Dashboard -->
        <RouterLink
          v-if="hasPerformanceDashboard"
          to="/performance-dashboard"
          class="drawer-item"
          active-class="drawer-item-active"
        >
          <MobileDrawerItem>Performance Dashboard</MobileDrawerItem>
        </RouterLink>

        <Divider class="mx-3" />

        <MobileDrawerItem link @click="detailView = 'invite-teammate'"
          >Invite teammate</MobileDrawerItem
        >

        <Divider class="mx-3" />

        <MobileDrawerItem @click="onSettingsClick">Settings</MobileDrawerItem>

        <Divider class="mx-3" />

        <MobileDrawerItem link @click="detailView = 'get-help'">Get help</MobileDrawerItem>
      </div>

      <PlanUsage
        class="mx-2"
        :total-job-slots="subscriptionService.jobSlots"
        :used-job-slots="subscriptionService.usedJobSlots"
      />

      <div class="flex flex-col">
        <Divider class="mx-3" />

        <MobileDrawerItem class="h-12">
          <UserInfo
            v-if="meService.userProfile"
            :user="meService.userProfile"
            @click="onUserClick"
          />
        </MobileDrawerItem>

        <Divider class="mx-3" />

        <MobileDrawerItem link class="h-12" @click="detailView = 'companies'">
          <CompanyInfo v-if="meService.employer" :employer="meService.employer" />
        </MobileDrawerItem>

        <Divider class="mx-3" />

        <MobileDrawerItem class="mt-6 font-bold" @click="onLogoutClick">Logout</MobileDrawerItem>
      </div>
    </div>

    <!-- Detail View -->
    <div v-else class="py-4">
      <div class="flex cursor-pointer items-center px-2" @click="detailView = undefined">
        <img :src="ChevronLeftSvg" class="h-5 w-5" />
        <span class="ml-1 text-sm font-semibold">Back</span>
      </div>

      <template v-if="detailView === 'companies'">
        <CompaniesContent />
      </template>

      <template v-else-if="detailView === 'get-help'">
        <GetHelpContent />
      </template>

      <template v-else-if="detailView === 'invite-teammate'">
        <InviteTeammateContent />
      </template>
    </div>
  </v-navigation-drawer>
</template>
